.recvessel-updated-form-container{
    --color: white;
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 20px 30px;
    border-radius: 20px;
    margin-top: 1rem;

}

.recvessel-updated-form [class*="col-"]:not(.recvessel-updated-field-checkbox-group [class*="col-"]):not(.recvessel-updated-field-radio-group [class*="col-"]),
.recvessel-updated-form-readonly-group [class*="col-"] {
  margin-bottom: .75rem;
}


.recvessel-updated-subtitle{
  font-size: 1.4rem;
  font-weight: 300;
  padding: 0 0 0.4rem 0;
  color: #444;
  line-height: 1.4;
}

.recvessel-updated-additional-text{
  opacity: 0.8;
  margin-top: 0.8rem;
}

.recvessel-updated-desc-text{
  opacity: 0.8;
}

.recvessel-updated-input-group{
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: .25rem;
}

.recvessel-updated-input-group > label {
  font-weight: 700;
  color: var(--main-blue);
}

.recvessel-updated-input-group input:not([type="radio"]):not([type="checkbox"]), select{
  font-size: 1rem;
  padding: .5rem;
  outline: none;
  border: none;
  border-radius: .25rem;
  color: rgb(109, 112, 114);
  box-shadow: 0 0 0 1px rgb(204, 204, 204);
  font-weight: lighter;
}


.recvessel-updated-input-group.success input:not([type="radio"]),
.recvessel-updated-input-group.success select{
  box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.recvessel-updated-input-group.error input:not([type="radio"]),
.recvessel-updated-input-group.error select{
  box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.recvessel-updated-input-group.success .recvessel-updated-field-radio-group
{
  box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.recvessel-updated-input-group.error .recvessel-updated-field-radio-group
{
  box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.recvessel-updated-input-group .msg {
  display: none;
  font-size: .9rem;
}

.recvessel-updated-input-group.success .msg,
.recvessel-updated-input-group.error .msg {
  display: block;
}

.recvessel-updated-input-group.error .msg {
  color: hsl(var(--error-hsl));
}

.recvessel-updated-input-group.success .msg {
  color: hsl(var(--success-hsl));
}

.recvessel-updated-input-group input:not([type="radio"]):not([type="checkbox"]):focus, select:focus{
  /* box-shadown is better than border */
  box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
}

.recvessel-updated-checkbox-group{
  display: flex;
  flex-direction: row;
  margin-bottom: 3px;
}

.recvessel-updated-checkbox-group input[type="checkbox"]
{
    margin: 0;
    margin-right: 5px;
}

/* Whole radio fields group */
.recvessel-updated-field-radio-group{
  border-radius: 0.25em;
}


.recvessel-updated-button{
  border-radius: 4px;
    font-size: 1.1rem;
    padding: 0.3rem 2.4rem;
    background: hsl(var(--main-blue-hsl), .8);
    color: #fff;
    border: none;
    outline: none;
    line-height: 2rem;
    font-weight: 600;
    transition: box-shadow .2s;
    width: 100%;
    margin-top: 2rem;
}

.recvessel-updated-button:hover{
  cursor: pointer;
  opacity: 0.7;
}