

.pna-holdings-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    min-width: 300px;
}

.pna-holdings-box{
    --color: white;
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);

    padding: 20px 30px;
    min-width: 300px;
    border-radius: 20px;

    min-height: 15vh;
    cursor: pointer;
}

/* .pna-holdings-box :hover{
    background-color: hsl(var(--main-blue-hsl), .5);
} */

.pna-holdings-label-box{
    border-bottom: 1px solid #0ae;
    margin: 0 0 .5rem;
    padding: 0 0 .25rem;
    text-transform: uppercase;
    font-family: work sans,sans-serif;

    display: flex;
    justify-content: space-between;
}

.pna-holdings-box label{
    
    color: hsl(var(--main-blue-hsl));
    font-weight: 700;
    font-size: .8rem;
    font-family: 'Work Sans', sans-serif;
}

.pna-holding-sub-container{
    font-size: .8rem;
}

.pna-holding-field{
    margin: 3px 0px;
    padding: 2px;
    background-color: hsla(210, 20%, 96%, 0.623);
}

.pna-holdings-container .center-block{
    padding: 25px 0px;
}

.pna-holdings-transfer.pna-holdings-box{
    cursor: default;
}

.pna-holding-tf-field:hover, .pna-holdings-label-box > label{
    cursor: pointer;
}