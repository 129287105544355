

.login-container-bg{
    background-image: url('../../Assets/P1360983-scaled.jpg');
    background-size: cover;
    background-position: center;
}

.login-container{
    --color: hsl(var(--primary-dark-hsl), .9);
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 40px 30px 20px 30px;
    width: 30%;
    min-width: 300px;
    border-radius: 20px;
}

.login-title{
    margin: 0;
    color: white;
    text-align: center;
    font-size: 2rem;
    font-weight: 700; 
    font-family: 'Work Sans', sans-serif;
}

.login-form{
    display: flex;
    flex-direction: column;
    color: white;
    gap: 12px;
    margin-top: 20px;
}

.login-input-group{
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.login-input-group label{
    color: white;
    font-weight: lighter;
}

.login-input-group input{
    font-size: 1.25rem;
    padding: .5rem;
    background-color: hsl(var(--primary-light-hsl), .3);
    border: none;
    outline: none;
    border-radius: .25rem;
    /* color: white; */
    font-weight: lighter; 
}

.login-input-group.success input{
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.login-input-group.error input{
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.login-input-group input:focus{
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
}

.login-button{
    padding: .5rem 1rem;
    font-size: 1.5rem;
    font-weight: lighter;
    color: white;
    background-color: hsl(var(--primary-hsl), .25);
    border: 1px solid hsl(var(--primary-hsl));
    border-radius: .25rem;
    outline: none;
    cursor: pointer;
}

.login-button:hover,
.login-button:focus{
    background-color: hsl(var(--primary-hsl), .4);
}

.login-links.align-right{
    flex-direction: row-reverse;
    margin-top: 0px;
}

.login-links{
    color: white;
    display: flex;
    flex-direction: row;
    gap: 7px;
    margin-top: 20px;
}

.login-links a:hover,
.login-links a:focus{
    color: hsl(var(--primary-light-hsl), .4);
}

.login-links a{
    text-decoration: none;
    color: cyan;
}