.recvessel-sub-container{
    /* --color: hsl(var(--primary-hsl), .2); */
    --color: white;
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 14px 30px;
    min-width: 300px;
    border-radius: 20px;
    margin-top: 1rem;
}

.recvessel-title-group{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0ae;
    margin: 0 0 1rem;
    text-transform: uppercase;
    font-family: 'Work Sans',sans-serif;
    
}

.recvessel-title-group button{
    padding: .5rem 1rem;
    font-size: 1rem;
    font-weight: lighter;
    color: white;
    background-color: hsl(var(--main-blue-hsl), .8);
    border: none;
    border-radius: .25rem;
    outline: none;
    cursor: pointer;
    height: min-content;
}

.recvessel-button:hover{
    background-color: hsl(var(--main-blue-hsl), .5);
}

.recvessel-add-icon{
    color: rgb(0, 170, 255);
}

.recvessel-add-icon:hover{
    opacity: .7;
    cursor: pointer;
}

.recvessel-title{
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: 700; 
    font-family: 'Work Sans', sans-serif;
}

.recvessel-input-group{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.recvessel-input-group label{
    font-weight: 700;
    color: var(--main-blue);
}

.recvessel-input-group .recvesselInfoField{
    background-color: hsl(207, 20%, 91%, 0.6);
    padding: 5px 5px;
    min-height: var(--input-field-min-height);
    border-radius: .25rem;
    border: none;
    outline: none;
    font-size: .95rem;
    word-wrap: break-word;
}

.recvessel-printer{
   /* color: rgb(0, 170, 255); */
   color: hsl(var(--main-blue-hsl), .8);
}

.recvessel-add-icon-box{
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.show-add-rec-less-650{
    display: none;
 }

 @media only screen and (max-width: 650px){
    .show-add-rec-less-650{
        display: block;
    }
    .hidden-add-rec-less-650{
        display: none;
    }
}

