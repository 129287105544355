.pwd-success-container{
    --color: hsl(var(--primary-dark-hsl), .8);
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 30px 40px;
    width: 20%;
    min-width: 300px;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    color: white;
    gap: 40px;
}

.pwd-success-title-box{
    /* background-color: lightsteelblue; */
    /* opacity: 0.6; */
    color: hsl(var(--success-hsl), .9);
    padding: 0.5rem;
    margin-bottom: 0;
    overflow-wrap: break-word;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    /* Not selectable */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.pwd-success-title{
    margin: 0;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Work Sans', sans-serif;
    letter-spacing: .14em;
}

.pwd-success-subtitle{
    margin: 0;
    color: lightgray;
    padding-top: .5rem;
    opacity: 0.7;
    text-align: center;
    font-size: 1rem;
    font-weight: normal;
    font-family: 'Work Sans', sans-serif;
}

.pwd-success-button{
    padding: .5rem 1rem;
    font-size: 1.25rem;
    font-weight: lighter;
    color: white;
    background-color: hsl(var(--primary-hsl), .25);
    border: 1px solid hsl(var(--primary-hsl));
    border-radius: .25rem;
    outline: none;
    cursor: pointer;

    
}

.pwd-success-button:hover,
.pwd-success-button:focus{
    background-color: hsl(var(--primary-hsl), .4);
}