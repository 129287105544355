.store-container{
    background-color: white;
    padding: .5rem 30px;
    min-width: 300px;
    width: 100vw;
    min-height: calc(100vh - var(--navbar-height) - var(--footer-height));
}

.loader-container{
    text-align: center;
}

.store-error-container{
    padding: 0 8vw;
}

.store-emptyItem-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: inherit;
    align-items: center;
}

.product{
    --color:white;
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    font-family: 'Arial', sans-serif;
}

.product-img-container{
    align-self: center;
    height: 150px;
    overflow: hidden;
}

.product-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-img:hover{
    cursor: pointer;
}

.ex-product-img{
    display: block;
    max-height: 95vh;
    height: auto;
    width: 100%;
}



.product-title{
    font-weight: 800;
    color: hsl(var(--main-blue-hsl), .8);
    line-height: 16px;
    /* 16*2 + 10 padding */
    min-height: 42px; 
    padding-top: 10px;
}

.text-over-2-ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.custom-tooltip-style{
    overflow-wrap: break-word;
    width: auto;
}

.product-desc{
    color: #565959;
    line-height: 16px;
    min-height: 32px;
    font-size: 12px;
}

.product-price{
    color: #B12704;
    line-height: 20px;
    font-size: 14px;
}

.product-stock-format{
    font-weight: 600;
}

.product-in-stock{
    color: #007600;
}

.product-out-stock{
    color: #dc3545;
}

.product-qty span{
    padding-right: 5px;
    font-size: 1rem;
}

.product-qty select{
    font-size: 1rem;
    padding: 0.2rem;
    outline: none;
    border: none;
    border-radius: 0.25rem;
    color: rgb(109, 112, 114);
    box-shadow: 0 0 0 1px rgb(204, 204, 204);
    font-weight: lighter;
}

.product-btn{
    background: #3498db;
    background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
    background-image: -moz-linear-gradient(top, #3498db, #2980b9);
    background-image: -ms-linear-gradient(top, #3498db, #2980b9);
    background-image: -o-linear-gradient(top, #3498db, #2980b9);
    background-image: linear-gradient(to bottom, #3498db, #2980b9);
    -webkit-border-radius: 28;
    -moz-border-radius: 28;
    border-radius: 28px;
    font-family: Arial;
    color: #ffffff;
    font-size: 14px;
    padding: 8px 16px 8px 16px;
    text-decoration: none;
    border: none;
    outline: none;
}

.product-btn:hover{
    background: #3cb0fd;
    background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
    background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
    text-decoration: none;
    cursor: pointer;
}

.product-qty{
    margin-top: 6px;
}

.product-stock-status, .product-btn{
    margin-top: 12px;
}

.cart-title{
    background: #eeeeee;
    padding-inline: 2rem;
}

.cart-container{
    --color: white;
    background-color: var(--color);
    /* padding: 14px 30px; */
    min-width: 300px;
    width: 100vw;
    min-height: calc(100vh - var(--navbar-height) - var(--footer-height));
    /* padding-top: var(--navbar-height); */
}

.cart-sub-container{
    padding: 2rem 2rem;
}

.cart-err-container{
    padding-inline: 2rem;
}

.cart-img-box{
    text-align: center;
}

.cart-img{
    max-width: 100%;
    height: auto;
    min-height: 80px;
    width: 100px;
}

.cart-selected-products-box{
    border-radius: 14px;
    border-color: #d0dfdf;
    border-width: 1px;
    border-style: solid;
    padding: 1rem 2rem;   
}

.cart-selected-products-box > .cart-selected-products-empty{
    display: flex;
}

.cart-product-inner-padding{
    padding: 1rem 0rem;
}

.cart-product-name{
    font-weight: 700;
}

.cart-product-desc{
    color: rgb(122, 120, 120);
    font-size: .8em;
}

.cart-qty-price-group{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;
}

.cart-total-price-each{
    font-weight: 700;
}

.cart-price-each{
    color: rgb(122, 120, 120);
    font-size: .8em;
}

.cart-remove-btn-box{
    text-align: center;
}

.remove-btn{
    -webkit-border-radius: 8;
    -moz-border-radius: 8;
    border-radius: 8px;
    font-family: Arial;
    color: #000000;
    font-size: 1rem;
    background: #ffffff;
    padding: 8px 16px 8px 16px;
    border: solid #d1d1d1 1px;
    text-decoration: none;
    font-weight: 600;
}

.remove-btn:hover{
    /* background: #3cb0fd; */
    background: #eeeeee;
    text-decoration: none;
    cursor: pointer;
}

.continue-btn{
    -webkit-border-radius: 8;
    -moz-border-radius: 8;
    border-radius: 8px;
    font-family: Arial;
    color: #000000;
    font-size: 1rem;
    background: #ffffff;
    padding: 8px 16px 8px 16px;
    border: solid #d1d1d1 1px;
    text-decoration: none;
    font-weight: 600;
    max-width: 200px;
}

.continue-btn:hover{
    /* background: #3cb0fd; */
    background: #eeeeee;
    text-decoration: none;
    cursor: pointer;
}


@media only screen and (max-width: 768px){
    .remove-btn{
        width: 300px;
    }
}

@media only screen and (max-width: 420px){
    .cart-sub-container {
        padding: 1rem 1rem;
    }
    .cart-selected-products-box {
        padding: 1rem 0.7rem;
    }
    .cart-product-inner-padding {
        padding: .6rem 0rem;
    }
    .remove-btn{
        width: 240px;
    }
}

.display-short-word{
    display: none;
}

.hide-long-word{
    display: block;
}

@media only screen and (max-width: 470px){
    .display-short-word{
        display: block;
    }
    .hide-long-word{
        display: none;
    }
}

.payway-errToken-box{
    border-radius: 14px;
    border-color: #d0dfdf;
    border-width: 1px;
    border-style: solid;
    padding: 1rem 2rem;
    line-height: 2;
    min-height: 250px;
}

.payway-errToken-box .pay-btn-group
{
    position: absolute;
    bottom: 0;
}

.cart-total-price-box{
    border-radius: 14px;
    border-color: #d0dfdf;
    border-width: 1px;
    border-style: solid;
    padding: 1rem 2rem;

}

.cart-total-price-box div{
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.price-display-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.line-separate{
    border-color: #a8a8a8;
    border-style: solid;
    border-top: 1px;
}

.credit-card-icons-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.cart-card-icon{
    width: 35px;
    height: auto;
}

.cart-btn-group{
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.purchase-btn{
    border-radius: 8px;
    font-family: Arial;
    font-size: 1rem;
    padding: 8px 16px 8px 16px;
    background: hsl(var(--main-blue-hsl), .8);
    color: #fff;
    border: none;
    outline: none;
    line-height: 2rem;
    font-weight: 600;
    transition: box-shadow .2s;
}

.purchase-btn:hover{
    cursor: pointer;
    opacity: 0.7;
}

.cart-pay-form-container{
    padding: 2rem 2rem;
    border-radius: 14px;
    border-color: #d0dfdf;
    border-width: 1px;
    border-style: solid;
    min-width: 300px;
}

.pay-btn-group{
    /* text-align: end; */
    padding: 1rem 0rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.client-address-group{
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.client-address-group > label{
    font-size: 1.2rem;
    font-weight: 600;
}

.client-address-radio-group{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: .5rem 0rem;
    border-radius: 0.25em;
}

.client-address-group.success .client-address-radio-group
{
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.client-address-group.error .client-address-radio-group
{
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.client-address-group .msg {
    display: none;
    font-size: .9rem;
  }
  
  .client-address-group.success .msg,
  .client-address-group.error .msg {
    display: block;
  }
  
  .client-address-group.error .msg {
    color: hsl(var(--error-hsl));
  }

@media only screen and (max-width: 500px){
    .cart-pay-form-container{
        padding: 2rem 1rem;
        border: none;
        min-width: 300px;
    }
}

@media only screen and (max-width: 991px){
    .cart-total-price-box{
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
}

.btn-cart-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btn-go-cart {
    background-color: #F28123;
    border: 0;
    border-radius: 56px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
    font-size: 18px;
    font-weight: 600;
    outline: 0;
    padding: 16px 32px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .btn-go-cart:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
  }
  
  .btn-go-cart:hover {
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
  }
  
  @media (min-width: 768px) {
    .btn-go-cart {
      padding: 12px 48px;
    }
  }