header{
    width: 100vw;
    height: var(--navbar-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    background-color: #fff;

    /* position: fixed;
    top: 0;  
    z-index: 1; */
}

.site-logo{
    margin: auto 0;
}

.site-logo img{
    max-width: 156px;
    height: var(--logo-height);
}

.navbar-toggler{
    display: none;
}

.navbar-toggler-cart{
    display: none;
}

  .dropdown-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 140px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    -webkit-animation: dropdown-animation 0.3s;
    animation: dropdown-animation 0.3s;
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
  }


  .navbar .dropdown-menu .dropdown-item {
    display: block;
    color: #000;
    padding: 10px 15px;
    font-size: 1rem;
    /* border-bottom: 1px solid black; */
    transition: color 0.2s ease-in;
  }

  .navbar .dropdown-menu .dropdown-item a {
    padding-left: 0px;
  }

  .dropdown:hover .dropdown-menu{
    display: block;
  }

  .navbar .dropdown-menu .dropdown-item:hover {
    color: #3ca0e7;
  }

  .navbar .dropdown-menu .dropdown-item a:hover{
    border-left: 3px solid #3ca0e7;
    padding-left: 9px;
    transition: all 0.35s ease;
  }

.navbar ul{
    display: flex;
    list-style: none;
}

.navbar ul li{
    border-left: 2px solid #3ca0e7;
}

.navbar a{
    color:inherit;
    text-decoration: none;
    display: block;
}

.nav-link{
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
}

.navbar ul li .nav-link{
    display: block;
    font-size: 1rem;
    padding: 3px 16px;
    transition: 0.2s;
}

.show-less-570{
    display: none;
}

.cart{
    display: inline-block;
}

.nav-chg-pwd-outer{
    display: flex;
    gap: 6px;
    align-items: center;
}

.nav-chg-pwd-inner{
    display: flex;
    flex-direction: column;
    /* gap: 2px; */
    /* font-size: 16px; */
}

@media only screen and (max-width: 570px){
    .show-less-570{
        display: inline;
    }
    .hidden-less-570{
        display: none;
    }
}

@media only screen and (max-width: 320px){
    .navbar ul li .nav-link{   
        font-size: .9rem;
    }

    .navbar .dropdown-menu .dropdown-item a:hover{
        padding-left: 5px;
      }
    
}



@media only screen and (max-width: 400px){
    .dropdown-menu .dropdown-item .nav-link{
        font-size: .8rem;
    }
}

@media only screen and (max-width: 700px){
    .dropdown-menu .dropdown-item .nav-link{
        font-size: .9rem;
    }
}



 .navbar ul li .nav-link:hover{
    color: #3ca0e7;
}


.navbar ul li .nav-link:active{
    color: #3ca0e7;
}

.cursor-pointer{
    cursor: pointer;
    /* Not selectable */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media only screen and (max-width: 1320px){
    header{
        padding: 0 50px;
    }
}

/* @media only screen and (max-width: 1100px){
    header{
        padding: 0 30px;
    }
} */

@media only screen and (max-width: 1100px){

    header{
        padding: 0 2rem;
    }

    .navbar{
        position: fixed;
        inset: 0 0 0 60%;
        z-index: 1000;
        
        background: hsl(0, 0%, 100%, 0.8);
        transform: translateX(100%);
        transition: transform 350ms ease-out;
    }

    /* If browser support it, then... */
    @supports (backdrop-filter: blur(1rem))
    {
        .navbar{
            background: hsl(0, 0%, 100%, 0.3);
            backdrop-filter: blur(1rem);
        }
    }

    .navbar ul{
        flex-direction: column;
        gap: 20px;
        padding: min(20vh, 4rem) 2rem;

    }

    .navbar-toggler{
        display: block;
        position: absolute;
        background: url('../Assets/icons8-menu.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 45px;
        height: 45px;
        top: .5rem;
        right: 2rem;
        border: none;
        outline: none;
        z-index: 9999;
    }

    .navbar-toggler-cart{
        display: block;
        position: absolute;
        top: 1.25rem;
        right: 5.5rem;
        border: none;
        outline: none;
        z-index: 9999;
        color: inherit;
    }

    .navbar.active{
        transform: translateX(0%);
    }

    .navbar-toggler.collapsed{
        background: url('../Assets/multipy.svg');
        width: 35px;
        height: 35px;
        top: .8rem;
        right: 2.2rem;
    }

}

/* @media only screen and (max-width: 700px){

    .navbar{
        position: fixed;
        inset: 0 0 0 65%;
        z-index: 1000;
        
        background: hsl(0, 0%, 100%, 0.8);
        transform: translateX(100%);
        transition: transform 350ms ease-out;
    }

} */

@media only screen and (max-width: 450px){

    header{
        padding: 0 1rem;
    }

    .navbar{
        position: fixed;
        inset: 0 0 0 42%;
        z-index: 1000;
        
        background: hsl(0, 0%, 100%, 0.8);
        transform: translateX(100%);
        transition: transform 350ms ease-out;
    }

    /* If browser support it, then... */
    @supports (backdrop-filter: blur(1rem))
    {
        .navbar{
            background: hsl(0, 0%, 100%, 0.3);
            backdrop-filter: blur(1rem);
        }
    }

}

@-webkit-keyframes dropdown-animation {
    0% {
      -webkit-transform: scaleY(0);
      -ms-transform: scaleY(0);
      transform: scaleY(0);
    }
    75% {
      -webkit-transform: scaleY(1.1);
      -ms-transform: scaleY(1.1);
      transform: scaleY(1.1);
    }
    100% {
      -webkit-transform: scaleY(1);
      -ms-transform: scaleY(1);
      transform: scaleY(1);
    }
  }

@keyframes dropdown-animation {
0% {
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
}
75% {
    -webkit-transform: scaleY(1.1);
    -ms-transform: scaleY(1.1);
    transform: scaleY(1.1);
}
100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
}
}

