.register-container-bg{
    background-image: url('../../Assets/P1490086-scaled.jpg');
    background-size: cover;
    background-position: center;
}

.register-form-container{
    --color: hsl(var(--primary-dark-hsl), .85);
    background-color: var(--color);
    box-shadow: 0 0 12px 0 var(--color);
    padding: 10px 30px;
    width: 50%;
    min-width: 300px;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.register-form-title{
    margin: 0;
    color: white;
    text-align: center;
    font-size: 2rem;
    font-weight: 700; 
    font-family: 'Work Sans', sans-serif;
}

.register-form{
    display: flex;
    flex-direction: column;
    color: white;
    gap: var(--field-gap);
    margin-top: 1rem;
}

.register-form-input-group{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.register-form-input-group label{
    color: white;
    font-weight: lighter;
}

.register-form-input-group input:not(.MuiInputBase-input){
    font-size: 1rem;
    padding: .5rem;
    background-color: hsl(var(--primary-light-hsl), .3);
    border: none;
    outline: none;
    border-radius: .25rem;
    color: white;
    font-weight: lighter; 
}

.register-form-input-group select{
    font-size: 1rem;
    padding: .5rem;
    background-color: hsl(var(--primary-light-hsl), .3);
    border: none;
    outline: none;
    border-radius: .25rem;
    color: white;
    font-weight: lighter; 
}

.register-form-input-group .MuiFormControl-root{
    font-size: 1rem;
    background-color: hsl(var(--primary-light-hsl), .3);
    border: none;
    outline: none;
    border-radius: .25rem;
    color: white;
    font-weight: lighter;
}

.register-form-input-group .MuiInputBase-input{
    font-size: 1rem;
    padding: .5rem;
    border: none;
    outline: none;
    border-radius: .25rem;
    color: white;
    font-weight: lighter; 
}

.register-form-input-group select option{
    background-color: hsl(var(--primary-dark-hsl), .6);
    color: white;
}

.register-form-input-group-checkbox{
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
}

.register-form-input-group-checkbox input[type="checkbox"]
{
    margin: 0;
    margin-right: 5px;
}

.register-form-input-group-checkbox label{
    align-items: center;
}

.register-form-input-group.success input:not(.MuiInputBase-input),
/* .register-form-input-group.success .MuiOutlinedInput-root, */
.register-form-input-group.success select{
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.register-form-input-group.error input:not(.MuiInputBase-input),
.register-form-input-group.error .MuiOutlinedInput-root,
.register-form-input-group.error select{
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

  .register-form-input-group .msg {
    display: none;
    font-size: .9rem;
  }
  
  .register-form-input-group.success .msg,
  .register-form-input-group.error .msg {
    display: block;
  }
  
  .register-form-input-group.error .msg {
    color: hsl(var(--error-hsl));
  }
  
  .register-form-input-group.success .msg {
    color: hsl(var(--success-hsl));
  }

.register-form-input-group input:not(.MuiInputBase-input):not(.MuiFormControl-root):focus-within,
select:focus
{
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
}

.register-form-input-group-checkbox-rego{
    height: 34px;
    align-items: center;
}

.register-form-input-group-checkbox-rego label{
    white-space: nowrap;
}

.register-form-rego-input-group{
    padding-left: 1rem;
}

.register-form-rego-input-group span{
    margin-top: 5px;
}

.register-form-rego-input-group.success input{
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.register-form-rego-input-group.error input{
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.register-form-rego-input-group input:focus{
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
}

 .register-form-rego-input-group .msg {
    display: none;
    font-size: .9rem;
  }
  
.register-form-rego-input-group.success .msg,
.register-form-rego-input-group.error .msg {
    display: block;
}

.register-form-rego-input-group.error .msg {
    color: hsl(var(--error-hsl));
}

.register-form-rego-input-group.success .msg {
    color: hsl(var(--success-hsl));
} 

@media only screen and (max-width: 992px){
    .register-form-rego-input-group, 
    .register-form-input-group-checkbox-rego {
        padding-left: 0;
    }
}

.register-form-rego-input-group input[type="text"]{
    font-size: 1rem;
    padding: .5rem;
    background-color: hsl(var(--primary-light-hsl), .3);
    border: none;
    outline: none;
    border-radius: .25rem;
    color: white;
    font-weight: lighter;
    width: 100%;
}

.register-form-button{
    padding: .5rem 1rem;
    font-size: 1.25rem;
    font-weight: lighter;
    color: white;
    background-color: hsl(var(--primary-hsl), .25);
    border: 1px solid hsl(var(--primary-hsl));
    border-radius: .25rem;
    outline: none;
    cursor: pointer;
    margin-top: 10px;

}

.register-form-button:hover,
.register-form-button:focus{
    background-color: hsl(var(--primary-hsl), .4);
}

.register-mobile-email-caption{
    padding-top: 10px;
    font-size: 14px;
}

.register-notification{
    font-weight: 550;
    font-size:16px
}

.register-additional-info-title{
    padding-top: 10px;
}

/* Create pwd -- start */

.create-pwd-container{
    --color: hsl(var(--primary-dark-hsl), .8);
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 30px 30px;
    width: 30%;
    min-width: 500px;
    border-radius: 20px;
}

.create-pwd-title{
    margin: 0;
    color: white;
    text-align: left;
    font-size: 1.5rem;
    font-weight: normal;
    font-family: 'Work Sans', sans-serif;
}

.create-pwd-subtitle{
    margin: 0;
    color: red;
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Work Sans', sans-serif;
}

.create-pwd-word{
    margin: 0;
    color: white;
    text-align: left;
    font-size: 1.25rem;
    font-weight: 400;
}

.create-pwd-form{
    display: flex;
    flex-direction: column;
    color: white;
    gap: 20px;
    margin-top: 20px;
}

.create-pwd-input-group{
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.create-pwd-input-group input{
    font-size: 1.25rem;
    padding: .5rem;
    background-color: hsl(var(--primary-light-hsl), .3);
    border: none;
    outline: none;
    border-radius: .25rem;
    /* color: white; */
    font-weight: lighter; 
}

.create-pwd-input-group.success input{
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.create-pwd-input-group.error input{
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.create-pwd-input-group input:focus{
    box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
}

.create-pwd-button{
    padding: .5rem 1rem;
    font-size: 1.5rem;
    font-weight: lighter;
    color: white;
    background-color: hsl(var(--primary-hsl), .25);
    border: 1px solid hsl(var(--primary-hsl));
    border-radius: .25rem;
    outline: none;
    cursor: pointer;
    margin-top: 1rem;
}

.create-pwd-button:disabled{
    pointer-events: none;
    opacity: 0.5;
}

.create-pwd-button:hover,
.create-pwd-button:focus{
    background-color: hsl(var(--primary-hsl), .4);
}


