.dialogBackground {
    width: 100vw;
    height: 100%;
    background-color: rgba(49, 49, 49, 0.8);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 999;
    animation: none;
  }
  
  .dialogContainer {
    width: 50%;
    max-width: 500px;
    min-width: 300px;
    min-height: 180px;
    border-radius: 16px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem 1.5rem;
    animation: show .3s ease-in forwards;
  }
  
  .dialog-header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #0ae;
      padding-bottom: .5rem;
  }
  
  .dialogContainer .dialog-title {
    font-weight: 500;
    font-size: calc(1.275rem + .3vw);
  }

  .dialogContainer .dialog-subtitle{
    padding-top: .25rem;
    font-size: 0.9rem;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  
  .titleCloseBtn .xmark-icon:hover{
      opacity: .6;
      cursor: pointer;
  }
  
  .dialogContainer .dialog-body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.25;
  }

  .dialogContainer .dialog-list-body {
    line-height: 1.25;
  }
  
  .dialog-list-body{
    padding-inline: .5rem;
    padding-top: .35rem;
    padding-bottom: .35rem;
    max-height: 35vh; 
    overflow-y: auto;
  }

  .dialog-list-row{
    margin: 3px 0px;
    padding: 2px;
    background-color: hsla(210, 20%, 96%, 0.623);
  }

  .dialog-list-row:hover{
    cursor: pointer;
    background-color:hsla(200, 100%, 91%, .9);
  }

  
  @media (min-width: 1200px){
      .title {
          font-size: 1.5rem;
        }
  }
  
  @keyframes show{
      from {
        opacity: 0;
        transform: translate(-50%, -100%);
      }
      to {
        opacity: 1;
        transform: translate(-50%, -50%);
      }
    }
    
    @keyframes hide{
      from {
        opacity: 1;
        transform: translate(-50%, -50%);
      }
      to {
        opacity: 0;
        transform: translate(-50%, 0%);
      }
    }