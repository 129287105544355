.modalBackground {
  width: 100vw;
  height: 100%;
  background-color: rgba(49, 49, 49, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 999;
  animation: none;
}

.modalContainer {
  width: 50%;
  max-width: 500px;
  min-width: 300px;
  /* height: 250px; */
  border-radius: 24px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem 1.5rem;
  animation: show .3s ease-in forwards;
}

.modal-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #0ae;
    padding-bottom: .5rem;
}

.modalContainer .modal-title {
  font-weight: 500;
  font-size: calc(1.275rem + .3vw);
  transform: translateZ(0);
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}


.titleCloseBtn .xmark-icon:hover{
    opacity: .6;
    cursor: pointer;
}

.modalContainer .modal-body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateZ(0);
  max-height: 40vh; 
  overflow-y: auto;
}

.modalContainer .modal-body-newBoat {
  flex: 50%;
  display: flex;
  flex-direction: column;
  min-height: 120px;
  transform: translateZ(0);
}

.modalContainer .modal-footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .modal-footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  /* animation: hide .3s ease-out forwards; */
}

.modalContainer .submit, .modalContainer .approvalBtn{
    background: linear-gradient(90deg, #22c0ff, #0ae);
}

.modalContainer .cancelBtn {
  background-color: crimson;
}


.modalContainer .modal-footer button:hover{
    opacity: .7;
    
}

@media (min-width: 1200px){
    .title {
        font-size: 1.5rem;
      }
}

@keyframes show{
    from {
      opacity: 0;
      transform: translate(-50%, -100%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  
  @keyframes hide{
    from {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
    to {
      opacity: 0;
      transform: translate(-50%, 0%);
    }
  }

  .modal-licence{
    height: 4rem;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: none;
  }