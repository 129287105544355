footer{
    text-align: left;
    background-color: #00508f;
    color: #fff;
    padding: 14px 20px;

}

footer .icon-author{
    font-size: 10px;
    
}

.icon-author a{
    text-decoration: none;
    /* color: rgba(0, 116, 217, 1); */
    color: #fff;
}

.footer-gp{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}