:root{
    --field-group-gap:14px;
}

.br-box{
    padding: 0 0 1rem 0;
}

.br-title{
    font-size: 1.8rem;
    font-weight: 400;
    padding: 0 0 0.4rem 0;
    margin: 0 0 .8rem 0;
    border-bottom: 1px solid #0ae;
    color: #444;
    line-height: 1.4;
}

.br-subtitle{
    font-size: 1.4rem;
    font-weight: 300;
    padding: 0 0 0.4rem 0;
    
    color: #444;
    
    line-height: 1.4;
}

.br-title.amount-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

p.sublabel{
    font-weight: 600;
}



.br-desc-text{
    opacity: 0.8;
}

.br-additional-text{
    opacity: 0.8;
    margin-top: 0.8rem;
}

.br-btn-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.br-safe-btn-group{
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    margin-top: 1rem;
}

.br-btn-group-success-page{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
}

.br-button{
    border-radius: 4px;
    font-size: 1.1rem;
    padding: 0.3rem 2.4rem;
    background: linear-gradient(90deg, #22c0ff, #0ae);
    color: #fff;
    border: none;
    outline: none;
    line-height: 2rem;
    font-weight: 600;
    transition: box-shadow .2s;
}

.br-button:hover{
    cursor: pointer;
    opacity: 0.7;
}

/* spacing each field group */
.br-field-group{
    display: flex;
    flex-direction: column;
    gap: var(--field-group-gap);
}


.br-field-readonly{
    font-size: 1rem;
    min-height: 34.4px;
    padding: .5rem;
    outline: none;
    border: none;
    border-radius: .25rem;
    color: rgb(109, 112, 114);
    box-shadow: 0 0 0 1px rgb(204, 204, 204);
    font-weight: lighter;
    background-color: rgb(204, 204, 204, 0.3);
}

.br-radio-field-readonly{
    font-size: 1rem;
    padding: .125rem;
    outline: none;
    border: none;
    border-radius: .25rem;
    color: rgb(109, 112, 114);
    box-shadow: 0 0 0 1px rgb(204, 204, 204);
    font-weight: lighter;
    background-color: rgb(204, 204, 204, 0.3);
}

.br-hin-group{
  display: flex;
  flex-flow: row;
  align-items: baseline;
  gap: 5px;
}

.br-hin-additional-text{
    padding-top: 5px;
    font-size: .9rem;
    font-weight: 600;
}

.br-hin-rest-input{
    flex: 1 1 auto;
}

.br-hin-country-code{
    min-width: 80px;
    width: 100%;
    max-width: 200px;

}
.br-hin-width-12{
    min-width: 10%;
    width: 12%;
}

.br-hin-width-13{
    min-width: 25%;
    width: 25%;
}

.br-hin-width-20{
    min-width: 18%;
    width: 20%;
}

.br-hin-width-30{
    min-width: 28%;
    width: 30%;
}

.br-hin-width-80{
    width: 73%;
}

.br-hin-width-100{
    width: 100%;
}

.br-input-group{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.br-input-group > label{
    font-weight: 600;
    color: var(--main-blue);
}

.br-input-group input:not([type="radio"]):not([type="checkbox"]):not([id*="react-select-"]):not(.MuiInputBase-input), 
select,
.br-input-group .upload-btn-wrap, textarea{
    font-size: 1rem;
    padding: .5rem;
    outline: none;
    border: none;
    border-radius: .25rem;
    color: rgb(109, 112, 114);
    box-shadow: 0 0 0 1px rgb(204, 204, 204);
    font-weight: lighter;
}

.br-input-group textarea{
    font-family: 'Work Sans', sans-serif;
    /* font-family: 'Arial', sans-serif; */
}

/* These are for DatePicker */
/* .br-input-group .MuiFormControl-root{
    font-size: 1rem;
    border: none;
    outline: none;
    border-radius: .25rem;
    color: rgb(109, 112, 114);
    font-weight: lighter;
} */

.br-input-group .MuiInputBase-input{
    font-size: 1rem;
    padding: .5rem;
    border: none;
    outline: none;
    border-radius: .25rem;
    color: rgb(109, 112, 114);
    font-weight: lighter; 
}


.br-input-group.success input:not([type="radio"]):not([id*="react-select-"]):not(.MuiInputBase-input),
.br-input-group.success select,
.br-input-group.success .upload-btn-wrap,
.br-hin-input-group.success input:not([type="radio"]):not([id*="react-select-"]):not(.MuiInputBase-input)
{
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.br-input-group.error input:not([type="radio"]):not([type="checkbox"]):not([id*="react-select-"]):not(.MuiInputBase-input),
.br-input-group.error .MuiOutlinedInput-root,
.br-input-group.error select,
.br-input-group.error .upload-btn-wrap,
.br-hin-input-group.error input:not([type="radio"]):not([type="checkbox"]):not([id*="react-select-"]):not(.MuiInputBase-input)
{
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.br-input-group.success .br-field-radio-group
{
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.br-input-group.error .br-field-radio-group
{
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.br-input-group .msg,
.br-hin-input-group .msg 
{
    display: none;
    font-size: .9rem;
  }
  
  .br-input-group.success .msg,
  .br-input-group.error .msg,
  .br-hin-input-group.success .msg,
  .br-hin-input-group.error .msg {
    display: block;
  }
  
  .br-input-group.error .msg,
  .br-hin-input-group.error .msg {
    color: hsl(var(--error-hsl));
  }
  
  .br-input-group.success .msg,
  .br-hin-input-group.success .msg {
    color: hsl(var(--success-hsl));
  }

  .br-input-group input:not([type="radio"]):not([type="checkbox"]):not([id*="react-select-"]):not(.MuiInputBase-input):not(.MuiFormControl-root):focus-within,
   select:focus,
   .br-input-group .upload-btn-wrap:focus-within,
   .br-hin-input-group input:not([type="radio"]):not([type="checkbox"]):not([id*="react-select-"]):not(.MuiInputBase-input):not(.MuiFormControl-root):focus-within,
   select:focus{
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
}

.br-field-checkbox-group{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.br-checkbox-group{
    display: flex;
    flex-direction: row;
}

/* Whole radio fields group */
.br-field-radio-group{
    border-radius: 0.25em;
}

/* Each group of radio (radio & label) */
/* .br-radio-group{

} */


.br-checkbox-group input[type="checkbox"]
{
    margin: 0;
    margin-right: 5px;
}

.br-card-position{
    display: flex;
    justify-content: center;
}

.cardFullName{
    text-transform: uppercase;
}

.br-engine-desc-container{
    padding: 0 1rem;
}

.br-engine-bin-icon{
    color: #ddd;
}
.br-engine-bin-icon:hover{
    color: red;
    cursor: pointer;
}

.br-add-engine-box{
    padding: 1.5rem 0;
    border-top: 1px dotted rgb(204, 204, 204);
    border-bottom: 1px dotted rgb(204, 204, 204);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.br-add-engine-box span{
    padding-right: .7rem;
}

.br-engine-add-icon{
    color: rgb(0, 170, 255);
}

.br-engine-add-icon:hover{
    opacity: .7;
    cursor: pointer;
}

.not-unsafe-gap{
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    margin-left: 80px;
}


@media only screen and (max-width: 991px){
    .br-card-position{
        margin-top: var(--field-group-gap);
    }
}

@media only screen and (max-width: 769px){
    .br-secure-code-group{
        margin-top: var(--field-group-gap);
    }

    .br-button{
        padding: 0.1rem 1.2rem;
    }
}

.expired-ves-field:hover{
    cursor: pointer;
    background-color: rgb(243, 244, 247);
}

.expired-ves-field{
    color: hsl(var(--main-blue-hsl));
    font-weight: 700;
    font-size: 1rem;
    font-family: 'Work Sans', sans-serif;
    margin-top: .25rem;
    padding: 4px;
}

.re-regi-title{
    color: hsl(var(--main-blue-hsl));
    font-weight: 700;
    font-size: 1.75rem;
    font-family: 'Work Sans', sans-serif;
    padding-bottom: .65rem;
    text-align: center;
}

.pop-msg-radio-group{
    display: flex;
    align-items: baseline;
    padding-top: .3rem;
    line-height: 1.25;
    text-align: justify;
}

.pop-msg-radio-group label{
    padding-left: 3px;
}


