.tsf-sub-container{
    width: 100vw;
    height: calc(100vh - var(--navbar-height) - var(--footer-height));
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tsf-field-group{
    display: flex;
    flex-direction: column;
    gap: var(--field-group-gap);
}

.tsf-title-container{
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1em;
}


.tsf-title-group{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0ae;
    margin: 0 0 1rem;
    text-transform: uppercase;
    font-family: 'Work Sans',sans-serif;
}

.tsf-box{
    width: 100%;
    min-height: 100px;
    border-radius: 14px;
    border-color: #d0dfdf;
    border-width: 1px;
    border-style: solid;
    padding: 1rem 2rem;
}

.lodge-box{
    min-height: 120px;
}

.tsf-upload-file-field{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tsf-upload-file-field input{
    width: 100%;
}

.tsf-upload-file-field-icon{
    padding: .5rem;
    font-size: 1.5rem;
    color: hsl(var(--primary-danger-red-hsl), .8);;
}

.tsf-upload-file-field-icon:hover{
    cursor: pointer;
    opacity: 0.7;
}

.tsf-btn-reject-gp{
    display: flex;
    flex-direction: row;
    gap: .5rem;
}

.tsf-button-reject{
    border-radius: 4px;
    font-size: 1.1rem;
    padding: 0.3rem 2.4rem;
    background: hsl(var(--primary-danger-red-hsl), .8);;
    color: #fff;
    border: none;
    outline: none;
    line-height: 2rem;
    font-weight: 600;
    transition: box-shadow .2s;
}

.tsf-button-reject:hover{
    cursor: pointer;
    opacity: 0.7;
}

.tsf-doc-title-gp{
    padding-bottom: 5px;
}

.tsf-sub-text{
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
}

.tsf-radio-container{
    display: flex;
    flex-direction: column;
    gap: .75rem;
}

.tsf-radio-container > label{
    font-weight: 600;
    color: var(--main-blue);
}

.tsf-radio-group{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.tsf-radio-field{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.tsf-radio-field > input[type="radio"]{
    margin-top: 0;
    width: 1.45em;
    height: 1.45em;
}