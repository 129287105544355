.licence-sub-container{
    /* --color: hsl(var(--primary-hsl), .2); */
    --color: white;
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 14px 30px;
    min-width: 300px;
    border-radius: 20px;
    margin-top: 1rem;
}

.licence-title-group{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0ae;
    margin: 0 0 1rem;
    text-transform: uppercase;
    font-family: 'Work Sans',sans-serif;
    
}

.licence-title-group .licence-button{
    padding: .5rem 1rem;
    font-size: 1rem;
    font-weight: lighter;
    color: white;
    background-color: hsl(var(--main-blue-hsl), .8);
    border: none;
    border-radius: .25rem;
    outline: none;
    cursor: pointer;
    height: min-content;
}

.licence-button:hover{
    background-color: hsl(var(--main-blue-hsl), .5);
}



.licence-title{
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: 700; 
    font-family: 'Work Sans', sans-serif;

    display: flex;
    flex-direction: row;
    gap: 5px;
}

.licence-sub-title{
    font-size: .9rem;
    font-weight: 600;
    padding: 0 0 1rem 0;
    
    color: #444;

}

.licence-input-group{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.licence-input-group label{
    font-weight: 700;
    color: var(--main-blue);
}

.licence-input-group .licenceInfoField{
    background-color: hsl(207, 20%, 91%, 0.6);
    padding: 5px 5px;
    min-height: var(--input-field-min-height);
    border-radius: .25rem;
    border: none;
    outline: none;
}

.cancel-licence-button{
    padding: .5rem 1rem;
    font-size: 1rem;
    font-weight: lighter;
    color: white;
    background: hsl(var(--primary-danger-red-hsl), .8);
    border: none;
    border-radius: .25rem;
    outline: none;
    cursor: pointer;
    height: min-content;

}

.cancel-licence-button:hover{
    background-color: hsl(var(--primary-danger-red-hsl), .5);
}

.licence-printer{
    /* color: rgb(0, 170, 255); */
    color: hsl(var(--main-blue-hsl), .8);
 }

 .licence-btn-gp{
    display: flex;
    flex-direction: row;
    gap: 5px;
 }

 .show-lic-less-700{
    display: none;
 }

 @media only screen and (max-width: 700px){
    .show-lic-less-700{
        display: block;
    }
    .hidden-lic-less-700{
        display: none;
    }
}

.show-lic-less-400{
    display: none;
 }

 @media only screen and (max-width: 400px){
    .show-lic-less-400{
        display: block;
    }
    .hidden-lic-less-400{
        display: none;
    }
}