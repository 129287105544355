.pna-updated-form-container{
    --color: white;
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 20px 30px;
    border-radius: 20px;

}

.pna-updated-form-input-group{
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: .75rem;
}

.pna-updated-form-input-group label{
    font-weight: 700;
    color: var(--main-blue);
}

.pna-updated-form-input-group input{
    font-size: 1rem;
    padding: .5rem;
    outline: none;
    border-radius: .25rem;
    font-weight: lighter; 
    border:1px solid #e7e7e7;
    background-color:white;
}

.pna-updated-form-input-group.success input:not([id*="react-select-"]){
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.pna-updated-form-input-group.error input:not([id*="react-select-"]){
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.pna-updated-form-input-group .msg {
    display: none;
    font-size: .9rem;
  }

  .pna-updated-form-input-group.success .msg,
  .pna-updated-form-input-group.error .msg{
    display: block;
  }

  .pna-updated-form-input-group.error .msg{
    color: hsl(var(--error-hsl));
  }

  .pna-updated-form-input-group.success .msg{
    color: hsl(var(--success-hsl));
  }

  .pna-updated-form-input-group input:focus:not([id*="react-select-"]){
    box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
  }

  .pna-updated-form-button{
    border-radius: 4px;
    font-size: 1.1rem;
    padding: 0.3rem 2.4rem;
    background: hsl(var(--main-blue-hsl), .8);
    color: #fff;
    border: none;
    outline: none;
    line-height: 2rem;
    font-weight: 600;
    transition: box-shadow .2s;
    width: 100%;
    margin-top: 1.25rem;
}

.pna-updated-form-button:hover{
    cursor: pointer;
    opacity: 0.7;
}

.pna-updated-modal-form{
  width: 100%;
  padding: 1rem 5%
}

.pna-updated-form-verify{
  float: right;
  border-radius: 12px;
  padding: 0rem .75rem;
  background-color: #00508f;
  color: #fff;
  border: none;
  outline: none;
  line-height: 1.25rem;
  font-weight: 600;
  transition: box-shadow .2s;
}

.pna-updated-form-verify:hover{
  cursor: pointer;
  opacity: 0.7;
}



