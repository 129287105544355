.pwd-reset-bg{
    background-image: url('../../Assets/P1360983-scaled.jpg');
    background-size: cover;
    background-position: center;
}

.pwd-reset-mobile-email-caption{
    padding-top: 10px;
    font-size: 14px;
}

.pwd-reset-notification{
    font-weight: 550;
    font-size:16px
}

.pwd-reset-additional-info-title{
    padding-top: 10px;
}


/* Remember ID form -- begin */
.remId-container{
    --color: hsl(var(--primary-dark-hsl), .85);
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 30px 30px;
    width: 30%;
    min-width: 300px;
    border-radius: 20px;
}

.remId-title{
    margin: 0;
    color: white;
    text-align: center;
    font-size: 2rem;
    font-weight: 700; 
    font-family: 'Work Sans', sans-serif;

}

.remId-subtitle{
    color: white;
    font-size: 1rem;
    font-weight: lighter; 
    font-family: 'Work Sans', sans-serif;
}
.remId-form{
    display: flex;
    flex-direction: column;
    color: white;
    gap: .5rem;
    margin-top: 20px;
}

.remId-input-group{
    display: flex;
    flex-direction: row;
    /* gap: 2px; */
    height: 40px;
    align-items: center;
}

.remId-input-group label{
    /* display: flex; */
    align-items: center;
}

.inline-show.fullWidth{
    width: 100%;
}

.remId-form input[type="text"]{
    
    font-size: 1rem;
    padding: .5rem;
    background-color: hsl(var(--primary-light-hsl), .3);
    border: none;
    outline: none;
    border-radius: .25rem;
    font-weight: lighter; 
    height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    /* margin-left: 10px; */
}
/* 
.remId-form input[type="text"]::placeholder{
    color: white;
} */

.remId-form input[type="text"].success{
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.remId-form input[type="text"].error{
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.remId-form input[type="text"]:focus{
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
}

.remId-button{
    padding: .5rem 1rem;
    font-size: 1.5rem;
    font-weight: lighter;
    color: white;
    background-color: hsl(var(--primary-hsl), .25);
    border: 1px solid hsl(var(--primary-hsl));
    border-radius: .25rem;
    outline: none;
    cursor: pointer;
    margin-top: 1rem;
}

.remId-button:hover,
.remId-button:focus{
    background-color: hsl(var(--primary-hsl), .4);
}

/* Remember ID form -- End */

/* Password reset form filling -- start */

.pwd-reset-form-container{
    --color: hsl(var(--primary-dark-hsl), .85);
    background-color: var(--color);
    box-shadow: 0 0 12px 0 var(--color);
    padding: 15px 30px;
    width: 50%;
    min-width: 300px;
    border-radius: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.pwd-reset-form-title{
    margin: 0;
    color: white;
    text-align: center;
    font-size: 2rem;
    font-weight: 700; 
    font-family: 'Work Sans', sans-serif;
}

.pwd-reset-form{
    display: flex;
    flex-direction: column;
    color: white;
    gap: var(--field-gap);
    margin-top: 1rem;
}

.pwd-reset-form-input-group{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.pwd-reset-form-input-group label{
    color: white;
    font-weight: lighter;
}

.pwd-reset-form-input-group input:not(.MuiInputBase-input){
    font-size: 1rem;
    padding: .5rem;
    background-color: hsl(var(--primary-light-hsl), .3);
    border: none;
    outline: none;
    border-radius: .25rem;
    color: white;
    font-weight: lighter; 
}

.pwd-reset-form-input-group select{
    font-size: 1rem;
    padding: .5rem;
    background-color: hsl(var(--primary-light-hsl), .3);
    border: none;
    outline: none;
    border-radius: .25rem;
    color: white;
    font-weight: lighter; 
}

.pwd-reset-form-input-group .MuiFormControl-root{
    font-size: 1rem;
    background-color: hsl(var(--primary-light-hsl), .3);
    border: none;
    outline: none;
    border-radius: .25rem;
    color: white;
    font-weight: lighter;
}

.pwd-reset-form-input-group .MuiInputBase-input{
    font-size: 1rem;
    padding: .5rem;
    border: none;
    outline: none;
    border-radius: .25rem;
    color: white;
    font-weight: lighter; 
}


.pwd-reset-form-input-group select option{
    background-color: hsl(var(--primary-dark-hsl), .6);
    color: white;
}

.pwd-reset-form-input-group-checkbox{
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
}

.pwd-reset-form-input-group-checkbox input[type="checkbox"]
{
    margin: 0;
    margin-right: 5px;
}

.pwd-reset-form-input-group-checkbox label{
    align-items: center;
}

.pwd-reset-form-input-group.success input:not(.MuiInputBase-input),
.pwd-reset-form-input-group.success select{
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.pwd-reset-form-input-group.error input:not(.MuiInputBase-input),
.pwd-reset-form-input-group.error .MuiOutlinedInput-root,
.pwd-reset-form-input-group.error select{
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.pwd-reset-form-input-group .msg {
    display: none;
    font-size: .9rem;
  }
  
  .pwd-reset-form-input-group.success .msg,
  .pwd-reset-form-input-group.error .msg {
    display: block;
  }
  
  .pwd-reset-form-input-group.error .msg {
    color: hsl(var(--error-hsl));
  }
  
  .pwd-reset-form-input-group.success .msg {
    color: hsl(var(--success-hsl));
  }

.pwd-reset-form-input-group input:not(.MuiInputBase-input):not(.MuiFormControl-root):focus, select:focus{
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
}

.pwd-reset-form-input-group-checkbox-rego{
    height: 34px;
    align-items: center;
}

.pwd-reset-form-input-group-checkbox-rego label{
    white-space: nowrap;
}

.pwd-reset-form-rego-input-group{
    padding-left: 1rem;
}

.pwd-reset-form-rego-input-group span{
    margin-top: 5px;
}

.pwd-reset-form-rego-input-group.success input{
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.pwd-reset-form-rego-input-group.error input{
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.pwd-reset-form-rego-input-group input:focus{
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
}

 .pwd-reset-form-rego-input-group .msg {
    display: none;
    font-size: .9rem;
  }
  
.pwd-reset-form-rego-input-group.success .msg,
.pwd-reset-form-rego-input-group.error .msg {
    display: block;
}

.pwd-reset-form-rego-input-group.error .msg {
    color: hsl(var(--error-hsl));
}

.pwd-reset-form-rego-input-group.success .msg {
    color: hsl(var(--success-hsl));
} 

@media only screen and (max-width: 992px){
    .pwd-reset-form-rego-input-group, 
    .pwd-reset-form-input-group-checkbox-rego {
        padding-left: 0;
    }
}

.pwd-reset-form-rego-input-group input[type="text"]{
    font-size: 1rem;
    padding: .5rem;
    background-color: hsl(var(--primary-light-hsl), .3);
    border: none;
    outline: none;
    border-radius: .25rem;
    color: white;
    font-weight: lighter;
    width: 100%;
}


.pwd-reset-form-button{
    padding: .5rem 1rem;
    font-size: 1.25rem;
    font-weight: lighter;
    color: white;
    background-color: hsl(var(--primary-hsl), .25);
    border: 1px solid hsl(var(--primary-hsl));
    border-radius: .25rem;
    outline: none;
    cursor: pointer;
    margin-top: 10px;

}

.pwd-reset-form-button:hover,
.pwd-reset-form-button:focus{
    background-color: hsl(var(--primary-hsl), .4);
}

/* Password reset form filling -- End */

/* Update new password -- start */
.update-pwd-container{
    --color: hsl(var(--primary-dark-hsl), .8);
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 30px 30px;
    width: 30%;
    min-width: 300px;
    border-radius: 20px;
}

.update-pwd-title{
    margin: 0;
    color: white;
    text-align: left;
    font-size: 1.5rem;
    font-weight: normal;
    font-family: 'Work Sans', sans-serif;
}

.update-pwd-form{
    display: flex;
    flex-direction: column;
    color: white;
    gap: 20px;
    margin-top: 20px;
}

.update-pwd-input-group{
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.update-pwd-input-group input{
    font-size: 1.25rem;
    padding: .5rem;
    background-color: hsl(var(--primary-light-hsl), .3);
    border: none;
    outline: none;
    border-radius: .25rem;
    /* color: white; */
    font-weight: lighter; 
}

.update-pwd-input-group.success input{
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.update-pwd-input-group.error input{
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.update-pwd-input-group input:focus{
    box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
}

.update-pwd-button{
    padding: .5rem 1rem;
    font-size: 1.5rem;
    font-weight: lighter;
    color: white;
    background-color: hsl(var(--primary-hsl), .25);
    border: 1px solid hsl(var(--primary-hsl));
    border-radius: .25rem;
    outline: none;
    cursor: pointer;
}

.update-pwd-button:disabled{
    pointer-events: none;
    opacity: 0.5;
}

.update-pwd-button:hover,
.update-pwd-button:focus{
    background-color: hsl(var(--primary-hsl), .4);
}

/* Update new password -- end */