.mooring-sub-container{
    /* --color: hsl(var(--primary-hsl), .2); */
    --color: white;
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 14px 30px;
    min-width: 300px;
    border-radius: 20px;
    margin-top: 1rem;
}

.mooring-title-group{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0ae;
    margin: 0 0 1rem;
    text-transform: uppercase;
    font-family: 'Work Sans',sans-serif;
    
}

.mooring-title-group button{
    padding: .5rem 1rem;
    font-size: 1rem;
    font-weight: lighter;
    color: white;
    background-color: hsl(var(--main-blue-hsl), .8);
    border: none;
    border-radius: .25rem;
    outline: none;
    cursor: pointer;
    height: min-content;
}

.mooring-title-group .mo-cancel
{
  background-color: hsl(var(--primary-danger-red-hsl), .8);
}

.mooring-button:hover{
    background-color: hsl(var(--main-blue-hsl), .5);
}



.mooring-title{
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: 700; 
    font-family: 'Work Sans', sans-serif;
}

.mooring-input-group{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.mooring-input-group label{
    font-weight: 700;
    color: var(--main-blue);
}

.mooring-input-group .mooringInfoField{
    background-color: hsl(207, 20%, 91%, 0.6);
    padding: 5px 5px;
    min-height: var(--input-field-min-height);
    border-radius: .25rem;
    border: none;
    outline: none;
}



/* Mooring update */

.mooring-updated-container{
    /* --color: hsl(var(--primary-hsl), .2); */
    --color: white;
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 14px 30px;
    min-width: 300px;
    border-radius: 20px;
    margin-top: 1rem;
}

.mooring-updated-input-group{
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }

  .mooring-updated-input-group label{
    font-weight: 700;
    color: var(--main-blue);
}

  .mooring-updated-form [class*="col-"] {
    margin-bottom: 0.7rem;
  }

  .mooring-updated-input-group input:not([type="radio"]):not([type="checkbox"]):not(.MuiInputBase-input), select{
    font-size: 1rem;
    padding: .5rem;
    outline: none;
    border: none;
    border-radius: .25rem;
    color: rgb(109, 112, 114);
    box-shadow: 0 0 0 1px rgb(204, 204, 204);
    font-weight: lighter;
  }

  .mooring-updated-input-group .MuiInputBase-input{
    font-size: 1rem;
    padding: .5rem;
    outline: none;
    border: none;
    border-radius: .25rem;
    color: rgb(109, 112, 114);
    font-weight: lighter;
  }


  .mooring-updated-input-group.success input:not([type="radio"]):not(.MuiInputBase-input),
  .mooring-updated-input-group.success select{
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
  }

  .mooring-updated-input-group.error input:not([type="radio"]):not(.MuiInputBase-input),
  .mooring-updated-input-group.error select{
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
  }

  .mooring-updated-input-group input:not([type="radio"]):not([type="checkbox"]):not(.MuiInputBase-input):not(.MuiFormControl-root):focus-within, 
  select:focus
  {
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
  }

  .mooring-updated-input-group .msg {
    display: none;
    font-size: .9rem;
  }

  .mooring-updated-input-group.success .msg,
  .mooring-updated-input-group.error .msg {
    display: block;
  }

  .mooring-updated-input-group.error .msg {
    color: hsl(var(--error-hsl));
  }

  .mooring-updated-input-group.success .msg {
    color: hsl(var(--success-hsl));
  }

  .mooring-updated-button{
    border-radius: 4px;
      font-size: 1.1rem;
      padding: 0.3rem 2.4rem;
      background: hsl(var(--main-blue-hsl), .8);
      color: #fff;
      border: none;
      outline: none;
      line-height: 2rem;
      font-weight: 600;
      transition: box-shadow .2s;
      width: 100%;
      margin-top: 2rem;
  }

  .mooring-updated-button:hover{
    cursor: pointer;
    opacity: 0.7;
  }

  .mooring-icon-group{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 0rem;
    min-height: var(--input-field-min-height);
  }

  .mooring-icon-group button {
    background: transparent;
    border: none;
    outline: none;
  }
