/* @import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap'); */

*, *::before, *::after {
    box-sizing: border-box;
    /* font-family: Arial, Helvetica, sans-serif; */
}

[class*='col-']:not(.recvessel-updated-field-radio-group [class*="col-"]):not(.recvessel-updated-field-checkbox-group [class*="col-"]):not(.pwd-reset-form [class*="col-"]):not(.register-form [class*="col-"]):not(.register-form-input-group [class*="col-"]):not(.MuiButtonBase-root [class*="col-"]):not(.MuiSvgIcon-root [class*="col-"]):not(.cart-title [class*="col-"]):not(.tsf-field-group [class*="col-"])
{
    margin-bottom: 0.75rem;
}

/* Remove input number arrows */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

:root{
    --logo-height: 42px;
    --nav-padding: .25rem;
    --navbar-height: calc(var(--logo-height) + 4*var(--nav-padding));
    --footer-height:46px;
    --main-blue:#00508f;
    --main-blue-hsl:206, 100%, 28%;
    --primary-light-hsl:200, 100%, 91%;
    --primary-hsl:200, 100%, 50%;
    --primary-dark-hsl:200, 100%, 6%;
    --success-hsl: 100, 60%, 50%;
    --error-hsl: 0, 100%, 50%;
    --primary-light-green-hsl:174, 74%, 62%;
    --primary-light-blue-hsl:187, 77%, 61%;
    --primary-light-pink-hsl:301, 51%, 61%;
    --primary-blue-hsl:215, 67%, 50%;
    --primary-danger-red-hsl:0, 100%, 53%;
    --field-gap:10px;
    --input-field-min-height:30px;
    --bgColor: #dde5eb;
}


html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'roboto', sans-serif;
    font-weight: 400;
    /* background-color: dodgerblue; */
}


.persistCheck {
    font-size: 0.9rem;
    margin-top: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.persistCheck label {
    margin: 0;
}

.text-danger{
    color: red;
}

.text-warning {
    color: #ffc107;
}

.text-upper{
    text-transform: uppercase
}

[type="checkbox"] {
    /* For safari */
    /* -webkit-transform: scale(1.3, 1.3);  */
    height: 18px;
    width: 18px;
    margin: 2px 5px 2px 2px;
}

.react-datepicker__input-container input[type="text"]{
    width: 100%;
}

:disabled{
    pointer-events: none;
    opacity: 0.5;
}

.br-radio-group :disabled{
    opacity: 1;
}

input::placeholder{
    color: darkgrey;
}

.unSelectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.red-text-explain{
    font-size: 12px;
}

.instructions {
    font-size: 0.85rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    /* bottom: -10px; */
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.error-container{
    margin-top: .8rem;
    margin-bottom: .8rem;
}

.errmsg {
    background-color: lightsteelblue;
    opacity: 0.6;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0;
    overflow-wrap: break-word;

    /* Not selectable */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.uni-err-msg{
    display: block;
    color: hsl(var(--error-hsl));
}

.flexGrow {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.line {
    display: inline-block;
}

.pt-1{
    padding-top: 15px;
}
.pb-1{
    padding-bottom: 15px;
}

.pr-1{
    padding-right: 15px;
}

.field-gap{
    margin-bottom: 0;
}

/* col-md */
@media only screen and (max-width: 992px){
    .field-gap{
        margin-bottom: var(--field-gap);
    }
}

.change-pwd-container,
.home-container,
.licence-container,
.recvessel-container,
.mooring-container,
.comvessel-container,
.br-container{
    /* background-color: hsl(0, 0%, 93%, .4); */
    background-color: var(--bgColor);
    padding: 1rem 15vw;
    min-height: inherit;
}

.login-container-bg,
.pwd-reset-bg,
.register-container-bg{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: inherit;
}

.br-form-container{
    background-color:#fff;
    padding: 1rem 2rem;
    border-radius: 20px;
    color: #6d7072;
    margin-top: 1rem;
}

.br-form-container ul li+li, .br-form-container ol li+li{
    margin-top: 0.6rem;
}

.br-form-container a{
    color: #337ab7;
    text-decoration:none;
}

.br-form-container a:hover{
    text-decoration: underline;
}

.payway-group{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.payway-group button{
    background: #dc3545;
}



/* tooltip */
.tooltip {
    position: relative;
    display: inline-block;
    color: #dc3545 !important;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 180px;
    background-color: black;
    font-size: .9rem;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .tooltip-2 {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-2 .tooltip-text-2 {
    visibility: hidden;
    width: 100%;
    background-color: #6F7E8C;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -11px;
    font-size: .55em;
  }
  
  .tooltip-2 .tooltip-text-2::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #6F7E8C transparent transparent transparent;
  }
  
  .tooltip-2:hover .tooltip-text-2 {
    visibility: visible;
  }

  .tooltip-icon {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-icon .tooltip-text-icon {
    visibility: hidden;
    background-color: #6F7E8C;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 8px;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 50%;
    margin-left: -30px;
    font-size: .75em;
  }
  
  .tooltip-icon .tooltip-text-icon::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #6F7E8C transparent transparent transparent;
  }
  
  .tooltip-icon:hover .tooltip-text-icon {
    visibility: visible;
  }

  /* tooltip finish */


/* upload file css */
[type="file"] {
/* Style the color of the message that says 'No file chosen' */
    color: #878787;
}
[type="file"]::-webkit-file-upload-button {
    background: dodgerblue;
    border: 2px solid dodgerblue;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    outline: none;
    padding: 10px 25px;
    text-transform: uppercase;
    transition: all 1s ease;
}

[type="file"]::-webkit-file-upload-button:hover {
    background: #fff;
    border: 2px solid #535353;
    color: #000;
}

.btn-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 70%;
}
.cancel-btn {
    border-radius: 4px;
    font-size: 1.1rem;
    padding: 0.3rem 2.4rem;
    background: hsl(var(--primary-danger-red-hsl), .8);
    color: #fff;
    border: none;
    outline: none;
    line-height: 2rem;
    font-weight: 600;
    transition: box-shadow .2s;
    width: 100%;
    margin-top: 2rem;
}
.cancel-btn:hover{
    cursor: pointer;
    opacity: 0.7;
}

.icon:hover{
    cursor: pointer;
}

.icon-group{
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    align-items: center;
    min-height: var(--input-field-min-height);
    /* gap: 10%; */
}

/* icon beat effect */
.beat-effect{
    animation: 1.5s ease 0s infinite beat;
}

@keyframes beat {
    0%, 50%, 100% { transform: scale(1, 1); opacity: 1; }
    30%, 80% { transform: scale(.92, .92); opacity: .78; }
}

/* ----------------------------------------------- */

/* Step progress bar */
.progress-bar-container{
    padding: 20px 0px;
}

.indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.indexedStep.accomplished {
    background-color: rgba(0, 116, 217, 1);
}
/* Finish progress bar */

@media only screen and (max-width: 768px){
    .br-container{
        padding: var(--navbar-height) 1.5rem;
    }

    .br-form-container{
        padding: 1rem 1.5rem;
    }

    
}

@media only screen and (max-width: 550px){
    .change-pwd-container,
    .home-container,
    .br-container,
    .licence-container,
    .recvessel-container,
    .mooring-container,
    .comvessel-container{
        padding: var(--navbar-height) 1rem;
    }

    .br-form-container{
        padding: 1rem 1rem;
    }

}

/* ------------------- */
.upload-btn{
    border: none;
    color: #fff;
    background-color: #1e90ff;
    padding: 12px 27px;
    border-radius: 4px;
    font-size: .75rem;
    margin-right:3.5px;
    transition:  0.3s ease-in-out;
}

.upload-input-file:hover + button
{
    background-color: #fff;
    color: rgb(40, 40, 40);
    box-shadow: 0 0 0 2px rgb(40, 40, 40);
}


.quickWeb-btn{
    outline: none;
    border: none;
    background-color: #fff !important;
    cursor: pointer;
}


.quickWeb-sign{
    font-size: .8em;
    font-style: italic;
    font-weight: 600;
    color: red;
}

.doc-hint{
    font-size: .8em;
    padding-bottom: .2rem;
    font-weight: 600;
}

.msg-scroll-screen-y{
    max-height: 7rem;
}

.statusReason-format{
    font-size: .8rem;
}
