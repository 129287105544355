.act-sub-container{
    --color: white;
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 14px 30px;
    min-width: 300px;
    border-radius: 20px;
    margin-top: 1rem;
    
}

.act-scroll-screen-y{
    padding-inline: 1rem;
    font-size: .9em;
    max-height: 180px;
    overflow-y: scroll;
}

.act-title-group{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0ae;
    margin: 0 0 .5rem;
    text-transform: uppercase;
    font-family: 'Work Sans',sans-serif;
    
}

.act-title{
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: 700; 
    font-family: 'Work Sans', sans-serif;
}

.act-input-group{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}

.act-input-group .actInfoField{
    background-color: hsl(207, 20%, 91%, 0.6);
    padding: 3px 5px;
    min-height: var(--input-field-min-height);
    border-radius: .25rem;
    border: none;
    outline: none;
    font-size: .9em;
}