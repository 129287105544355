
:root{
    --card-min-height: 267px;
    --card-inner-warp-side-padding:40px;
    --card-inner-warp-top-padding:20px;
    --black-bar-height:40px;
    
}

.card-container{
    position: relative;
    width: 90%;
    max-width: 400px;
    height: 267px;
}

.Wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  
  .Base {
    background: #ccc;
    /* height: 378px;
    width: 595px; */
    min-width: 230px;
    max-width: 400px;
    width: 100%;
    height: auto;
    border-radius: 15px;

    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: -webkit-transform 0.8s;
    -webkit-transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    cursor: pointer;
  }
  
  .Inner-wrap {
    background-color: #0c0014;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%230d1838' points='1600%2C160 0%2C460 0%2C350 1600%2C50'/%3E%3Cpolygon fill='%230e315d' points='1600%2C260 0%2C560 0%2C450 1600%2C150'/%3E%3Cpolygon fill='%230f4981' points='1600%2C360 0%2C660 0%2C550 1600%2C250'/%3E%3Cpolygon fill='%231062a6' points='1600%2C460 0%2C760 0%2C650 1600%2C350'/%3E%3Cpolygon fill='%23117aca' points='1600%2C800 0%2C800 0%2C750 1600%2C450'/%3E%3C/g%3E%3C/svg%3E");
    background-size: auto 147%;
    background-position: center;
    position: relative;
    min-height: var(--card-min-height);
    height: 100%;
    width: 100%;
    border-radius: 25px;
    padding: var(--card-inner-warp-top-padding) var(--card-inner-warp-side-padding);
    box-sizing: border-box;
    color: #fff;

  }

  .Base .front,
  .Base .back{
    position: absolute;
    width: 100%;
    max-width: 400px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    /* color: #47525d; */
  }

  .Base .back{
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .Base.flipped {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

   .front-group{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -webkit-box-shadow: 1px 5px 6px 0px black;
    box-shadow: 1px 5px 6px 0px black;
  }

 .card-back-padding-bottom{
    padding-bottom: 60px;
    -webkit-box-shadow: 1px 5px 6px 0px black;
    box-shadow: 1px 5px 6px 0px black;
  }

  .Inner-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }


  .col-margin-bottom{
    margin-bottom: 10px;
  }



    .input-display{
    font-size: 1rem;
    /* padding: .5rem; */
    border: none;
    outline: none;
    border-radius: .25rem;
    width: 100%;
  }

  .card-chip{
    max-width: 60px;
    transform: scale(.9, 0.8);
}

  .visa-card-type{
    float: right;
    max-height: 2rem;
    width: auto;
  }

  .master-card-type{
    float: right;
    max-height: 3.6rem;
    width: auto;
  }

  .card-group code{
    opacity: .7;
  }


  .card-number{
    font-weight: 600;
    font-family: 'Source Code Pro', monospace;
    font-size: min(5.8vw, 1.88rem);
    text-align: justify;

    /* display: block;
    width: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card-number:after{
    content: "";
    display: inline-block;
    width: 100%;
  }

  .card-username{
    font-weight: 600;
    text-transform: uppercase;
  }

  .expire-group{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .valid-thru-group{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

  .valid-thru{
    text-align: center;
  }


  .card-exp{
    font-weight: 600;
    font-family: 'Source Code Pro', monospace;
    letter-spacing: .1rem;
    padding-left: .2rem;
  }

  .black-bar{
    position: absolute;
    height: var(--black-bar-height);
    width: 100%;
    background-color: black;
    margin:0 calc(0px - var(--card-inner-warp-side-padding));
    margin-top: .7rem;
}

.strip-bar{
    height: 36px;
    background: linear-gradient(
  to bottom,
  #FFFFFF,
  #FFFFFF 50%,
  #D3D3D3 50%,
  #D3D3D3
);
background-size: 100% 12px;
margin-top: calc(var(--card-inner-warp-top-padding) + var(--black-bar-height) + 10px);
color: black;
display: flex;
flex-direction: row;
align-items: center;
font-size: 1.3rem;
}

.signature{
    font-family: 'URW Chancery L', cursive;
    width: 85%;
    padding-left: .5rem;
    font-size: 1.2rem;
}

.secure-code{
    width:15%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.text-align-right{
    float: right;
    font-size: 1rem;
    
}

.down-bars {
    position: absolute;
    bottom: var(--card-inner-warp-top-padding);
    max-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.down-bars div{
    background-color: #FFFFFF;
    height: 10px;

}

.first-white-bar{
    
    width: 65%;
}

.sec-white-bar{
    width: 75%;
}

.icon-red{
    color: red;
}




@media only screen and (max-width: 480px){
    

 .card-number{
   font-size: 1.54rem;
 }

 .card-type{
     max-height: 1.6rem;
   }
   
 .signature{
     width: 80%;
 }
 
 .secure-code{
     width:20%;
 }
}


@media only screen and (max-width: 410px){
    
  .Inner-wrap{
    padding: 1rem 1.2rem;
}

.black-bar{
    margin: 1rem -1rem;
}

.signature{
    width: 77%;
}

.secure-code{
    width:23%;
}

.expire-group{
  gap: 4px;
}
}

@media only screen and (max-width: 370px){
    
  .card-number{
    font-size: 1.2rem;
  }

  .card-exp,
  .valid-thru-group{
    font-size: .75rem;
  }
}