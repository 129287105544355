.pna-details-container{
    /* --color: hsl(var(--primary-hsl), .2); */
    --color: white;
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 14px 30px;
    min-width: 300px;
    border-radius: 20px;
    margin-top: 1rem;
}

.pna-details-title-group{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid #0ae;
    margin: 0 0 1rem;
    text-transform: uppercase;
    font-family: 'Work Sans',sans-serif;
    
}

.pna-details-title-group button{
    padding: .5rem 1rem;
    font-size: 1rem;
    font-weight: lighter;
    color: white;
    background-color: hsl(var(--main-blue-hsl), .8);
    border: none;
    border-radius: .25rem;
    outline: none;
    cursor: pointer;
    height: min-content;
}

.pna-details-update-button:hover{
    background-color: hsl(var(--main-blue-hsl), .5);
}

.pna-details-title{
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: 700; 
    font-family: 'Work Sans', sans-serif;
}

.pna-details-input-group{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.pna-details-input-group label{
    font-weight: 700;
    color: var(--main-blue);
}

.pna-details-input-group .pnaInfoField{
    background-color: hsl(207, 20%, 91%, 0.6);
    padding: 5px 5px;
    min-height: var(--input-field-min-height);
    border-radius: .25rem;
    border: none;
    outline: none;
}