/* Choose verify method -- start */
.choose-method-container{
    --color: hsl(var(--primary-dark-hsl), .8);
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 30px 30px;
    width: 30%;
    min-width: 300px;
    border-radius: 20px;
}

.choose-method-title{
    margin: 0;
    color: white;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700; 
    font-family: 'Work Sans', sans-serif;
}

.choose-method-subtitle-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.choose-method-subtitle{
    color: white;
    font-size: .8rem;
    font-weight: lighter;
}

.choose-method-form{
    display: flex;
    flex-direction: column;
    color: white;
    gap: 20px;
}

.choose-method-input-group{
    display: flex;
    flex-direction: row;
    gap: 7px;
}

.choose-method-input-group input[type="radio"]{
    margin: 0;
    margin-right: 3px;
}

.choose-method-button{
    padding: .5rem 1rem;
    font-size: 1.5rem;
    font-weight: lighter;
    color: white;
    background-color: hsl(var(--primary-hsl), .25);
    border: 1px solid hsl(var(--primary-hsl));
    border-radius: .25rem;
    outline: none;
    cursor: pointer;
}

.choose-method-button:hover,
.choose-method-button:focus{
    background-color: hsl(var(--primary-hsl), .4);
}

.unable-choose-method-container{
    --color: hsl(var(--primary-dark-hsl), .8);
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 30px 30px;
    width: 40%;
    min-width: 300px;
    border-radius: 20px;
}

.unable-choose-method-container div{
    color: white;
    padding: 15px 0px;
}

/* Choose verify method -- End */

/* Verify code -- start */
.verify-code-container{
    --color: hsl(var(--primary-dark-hsl), .8);
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 30px 30px;
    width: 30%;
    min-width: 300px;
    border-radius: 20px;
}

.verify-code-title{
    margin: 0;
    color: white;
    text-align: center;
    font-size: 1.75rem;
    font-weight: 700; 
    font-family: 'Work Sans', sans-serif;
}

.verify-code-form{
    display: flex;
    flex-direction: column;
    color: white;
    gap: 20px;
    margin-top: 20px;
}

.verify-code-input-group{
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.verify-code-input-group input{
    font-size: 1.25rem;
    padding: .5rem;
    background-color: hsl(var(--primary-light-hsl), .3);
    border: none;
    outline: none;
    border-radius: .25rem;
    /* color: white; */
    font-weight: lighter; 
}

.verify-code-input-group.success input{
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--success-hsl));
}

.verify-code-input-group.error input{
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--error-hsl));
}

.verify-code-input-group input:focus{
    /* box-shadown is better than border */
    box-shadow: 0 0 0 1px hsl(var(--primary-hsl));
}


.verify-code-button{
    padding: .5rem 1rem;
    font-size: 1.5rem;
    font-weight: lighter;
    color: white;
    background-color: hsl(var(--primary-hsl), .25);
    border: 1px solid hsl(var(--primary-hsl));
    border-radius: .25rem;
    outline: none;
    cursor: pointer;
    margin-top: 1rem;
}

.verify-code-button:hover,
.verify-code-button:focus{
    background-color: hsl(var(--primary-hsl), .4);
}


/* Verify code -- end */

/* Unidentified user -- start */
.unidentified-user-container{
    --color: hsl(var(--primary-dark-hsl), .8);
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 30px 30px;
    width: 35%;
    min-width: 300px;
    border-radius: 20px;
    line-height: 1.6;
    font-size: 1.1em;
}

.unidentified-user-container div{
    color: white;
    padding: 15px 0px;
}

/* Unidentified user -- end */