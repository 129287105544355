.pna-subscriptions-container{
    /* --color: hsl(var(--primary-hsl), .2); */
    --color: white;
    background-color: var(--color);
    box-shadow: 0 0 15px 0 var(--color);
    padding: 14px 30px;
    min-width: 300px;
    border-radius: 20px;
    margin-top: 1rem;
}

.pna-subscriptions-title-group{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #0ae;
    margin: 0 0 1rem;
    text-transform: uppercase;
    font-family: 'Work Sans',sans-serif;
    
}

.pna-subscriptions-title-group button{
    padding: .5rem 1rem;
    font-size: 1rem;
    font-weight: lighter;
    color: white;
    background-color: hsl(var(--main-blue-hsl), .8);
    border: none;
    border-radius: .25rem;
    outline: none;
    cursor: pointer;
    height: min-content;
}

.pna-subscriptions-save-button:hover{
    background-color: hsl(var(--main-blue-hsl), .5);
}

.pna-subscriptions-title{
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: 700; 
    font-family: 'Work Sans', sans-serif;
}

.pna-subscriptions-checkbox-group{
    display: flex;
    flex-direction: row;
    /* padding-left: 1rem; */
    gap: .5rem;
}

.pna-subscriptions-checkbox-group label{
    font-weight: 600;
    color: var(--main-blue);
    align-items: center;
}

.pna-subscriptions-checkbox-group input[type="checkbox"]
{
    margin: 0;
    margin-right: 5px;
}